<template>
    <div :class="$style.setting" v-loading="loading" v-if="!loading">
        <page-header
            size="full"
            :class="$style.header"
            :visibleBack="true"
            :hasBorder="true"
            :backText="backText"
            :title="$t('goals.create_goal_title')"
            @back="handleBackClick"
        >
            <div ref="headerTools" :class="$style['header-tools']" slot="rightTools">
                <pt-icon
                    @click="toggleDialogVisible('description', !dialogVisible.description)"
                    icon="icon-recommend"
                    :icon-style="{
                        width: '24px',
                        height: '24px'
                    }"
                ></pt-icon>
                <pt-button size="medium" :disabled="!validGoalSetting" @click="handleTestSaveClick">{{
                    $t('common.button_save')
                }}</pt-button>
            </div>
        </page-header>
        <div class="x-wrap x-wrap--full" :class="$style.description" v-show="dialogVisible.description">
            <div :class="$style['description-arrow']" :style="descriptionArrowStyle"></div>
            <div :class="$style['description-container']">
                <pt-icon
                    :class="$style['description-close']"
                    @click="toggleDialogVisible('description', false)"
                    icon="pt-icon--close"
                    :icon-style="{
                        width: '16px',
                        height: '16px'
                    }"
                ></pt-icon>
                <div :class="$style['description-icon']">
                    <img src="@assets/images/goal/goal-add.png" />
                </div>
                <div :class="$style['description-info']">
                    <h3>{{ $t('goals.new_title') }}</h3>
                    <p>{{ $t('goals.new_description') }}</p>
                </div>
            </div>
        </div>
        <div :class="$style.container">
            <div class="x-wrap x-wrap--full">
                <div :class="$style.box">
                    <h4>{{ $t('goals.name') }}</h4>
                    <div :class="$style['box-content']">
                        <pt-input
                            :class="$style['box-ipt']"
                            size="large"
                            :placeholder="$t('goals.untitled_goal')"
                            v-model.trim="model.name"
                        ></pt-input>
                    </div>
                    <pt-button
                        type="text"
                        color="black"
                        v-if="model.description === null"
                        @click="handleDescriptionClick"
                        >+{{ $t('users.users_description') }}</pt-button
                    >
                </div>
                <div :class="$style.box" v-if="model.description !== null">
                    <h4>{{ $t('users.users_description') }}</h4>
                    <div :class="$style['box-content']">
                        <pt-input
                            :class="[$style['box-ipt'], $style['box-textarea']]"
                            type="textarea"
                            size="large"
                            :rows="2"
                            v-model="model.description"
                        ></pt-input>
                    </div>
                </div>
                <div :class="$style.box">
                    <h4>{{ $t('goals.define_goal') }}</h4>
                    <p>{{ $t('goals.define_goal_description') }}</p>
                    <div :class="$style['box-content']">
                        <el-select
                            size="small"
                            v-model="model.content.type"
                            :placeholder="$t('common.select_placeholder')"
                            @change="goalTypeChange"
                        >
                            <el-option
                                v-for="item in goalTypeList"
                                :key="item.code"
                                :label="item.text"
                                :value="item.code"
                            >
                            </el-option>
                        </el-select>
                        <template v-if="model.content.type === 'url'">
                            <div :class="$style.content_item">
                                <el-select
                                    size="small"
                                    v-model="model.content.condition"
                                    :placeholder="$t('common.select_placeholder')"
                                    @change="goalConditionChange"
                                >
                                    <el-option
                                        v-for="condition in goalConditionList"
                                        :key="condition.value"
                                        :label="condition.text"
                                        :value="condition.value"
                                    ></el-option>
                                </el-select>
                            </div>
                            <div
                                :class="[$style.content_item, $style.content_item_pagetype]"
                                v-if="model.content.condition && !originalPatternList.includes(model.content.condition)"
                            >
                                <el-select
                                    size="small"
                                    v-model="model.content.subType"
                                    :placeholder="$t('common.select_placeholder')"
                                >
                                    <el-option
                                        v-for="item in pageTypeList"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code"
                                    >
                                        <span :title="$t(item.name)">{{ $t(item.name) }}</span>
                                        <el-tooltip placement="top" :enterable="false">
                                            <div slot="content" v-html="$t(item.tip)"></div>
                                            <pt-icon
                                                icon="pt-icon--help-new"
                                                :icon-style="{
                                                    width: '16px',
                                                    height: '16px',
                                                    fill: '#344563',
                                                    'margin-left': '4px',
                                                    'vertical-align': 'text-bottom'
                                                }"
                                            ></pt-icon>
                                        </el-tooltip>
                                    </el-option>
                                </el-select>
                            </div>

                            <div :class="[$style.content_item, $style.content_item_value]" ref="conditionRef">
                                <ItemMultiSelectWithPopover
                                    class="pageSection"
                                    v-if="
                                        ['[()]', '=='].includes(model.content.condition) &&
                                            model.content.subType === 'page'
                                    "
                                    :data="model.content"
                                    :tag-placeholder="$t('common.or')"
                                    :value="model.content.value"
                                    :options="pageDictData"
                                    :trim-space="true"
                                    :allow-empty="false"
                                    :allow-create="true"
                                    :blur-create="true"
                                    @toggle="handleToggle"
                                    @change="data => goalValueChange(data.val)"
                                >
                                </ItemMultiSelectWithPopover>
                                <ItemMultiSelectWithPopover
                                    class="combinedPageSection"
                                    v-else-if="
                                        ['[()]', '=='].includes(model.content.condition) &&
                                            model.content.subType === 'combinedPage'
                                    "
                                    :data="model.content"
                                    :tag-placeholder="$t('common.or')"
                                    :value="model.content.value"
                                    :options="combinedPageDictData"
                                    :trim-space="true"
                                    :allow-empty="false"
                                    :allow-create="true"
                                    :blur-create="true"
                                    @toggle="handleToggle"
                                    @change="data => goalValueChange(data.val)"
                                >
                                </ItemMultiSelectWithPopover>
                                <el-input
                                    v-else
                                    v-model="model.content.value[0]"
                                    size="small"
                                    @change="goalValueChange"
                                ></el-input>
                            </div>
                        </template>
                        <template v-else>
                            <el-autocomplete
                                size="small"
                                :class="[$style.content_item, $style.content_item_value]"
                                v-model="model.content.value[0]"
                                clearable
                                value-key="code"
                                :placeholder="$t('common.select_placeholder')"
                                :fetch-suggestions="querySearchEvent"
                                @change="goalValueChange"
                                @select="goalValueChange"
                            ></el-autocomplete>
                            <pt-tooltip
                                :content="$t('goals.event_filter_tip')"
                                placement="top"
                                :visible-arrow="true"
                                v-if="
                                    model.content.value[0] &&
                                        currentEventPropertys &&
                                        currentEventPropertys.length > 0 &&
                                        model.content.where &&
                                        model.content.where.length <= 5
                                "
                            >
                                <pt-button
                                    type="text"
                                    color="black"
                                    :class="$style.iconButton"
                                    @click.prevent="handleAddEventVariant"
                                >
                                    <pt-icon
                                        icon="pt-icon-addfilter"
                                        :icon-style="{
                                            width: '24px',
                                            height: '24px',
                                            'vertical-align': 'baseline'
                                        }"
                                    ></pt-icon>
                                </pt-button>
                            </pt-tooltip>
                        </template>
                    </div>
                    <!-- 事件属性 当选择goal type 类型为event时才有 -->
                    <div
                        v-if="model.content.type === 'event' && model.content.value[0] && model.content.where"
                        :class="$style.eventVariant"
                    >
                        <template v-for="(item, index) in model.content.where">
                            <goal-event-variant
                                :key="index"
                                v-if="currentEventPropertys"
                                :index="index"
                                :filter-len="model.content.where && model.content.where.length"
                                :filter-items="currentEventPropertys"
                                :sid="profileInfo.sid"
                                :timeZone="profileInfo.timeZone"
                                :filter="item"
                                :event-name="model.content.value[0]"
                                :value-options="currentEventPropertys"
                                :property-value-options="eventPropertyValueOptions"
                                @updateItems="handleCacheValueOptions"
                                @change="val => handleUpdateWhere(index, val)"
                                @delete="handleDeleteEventVariant(index)"
                            >
                            </goal-event-variant>
                        </template>
                    </div>
                    <div :class="$style.rexTips" v-if="visibleRexTips">
                        <h3>{{ $t('goals.rex_tips_title') }}</h3>
                        <p v-html="$t('goals.rex_tips_content')"></p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 确认返回弹框-->
        <back-dialog
            v-if="dialogVisible.backDialog"
            @cancel="toggleDialogVisible('backDialog', false)"
            @confirm="handleConfirmBack"
        ></back-dialog>
        <!-- 更新goal的二次确认 -->
        <el-dialog center width="440px" :visible="updateGoalDialog" @close="updateGoalDialog = false">
            <div class="dialog-content">
                <p class="des">{{ $t('goals.goal_update_tip') }}</p>
            </div>
            <div slot="footer" class="dialog-footer">
                <pt-button class="pt-mr30" type="outline" color="black" @click="updateGoalDialog = false">
                    {{ $t('common.button_cancel') }}
                </pt-button>
                <pt-button @click="handleSaveClick">
                    {{ $t('common.button_confirm') }}
                </pt-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import isEqual from 'lodash/isEqual';
import PageHeader from '@/common/packages/page-header/PageHeader';
import GoalEventVariant from './GoalEventVariant.vue';
import BackDialog from './dialogs/BackDialog';
import ItemMultiSelectWithPopover from '@/components/users/setting/filter/items/item-multi-select-with-popover.vue';
import usersApis from '@/apis/users.apis';
import goalApis from '@/apis/goal.apis';
import commonUtils from '@common/utils/common.utils';
import { mapGetters, mapMutations } from 'vuex';
import settingConfig from '@/components/campaign/setting/setting.config';
import cloneUtils from '@common/utils/clone.utils';
import domUtils from '@/common/utils/dom.utils';
import permissionUtils from '@/common/utils/permission';
import PermissionConfig from '@/common/configs/permissions.config';
import analyticsService from '@/common/services/analytics.service';
import { getNewPteUserInfo } from '@/common/services/singleSpa.service';

export default {
    name: 'GoalsCreate',
    data() {
        const hasGoalRexPermission = permissionUtils.includePermissionWithStore([PermissionConfig.goalRex]);
        const { originalPatternList, pageTypeList, entryPatternList } = settingConfig;
        const isCreated = this.$route.name === 'GoalsCreate';
        const dialogVisible = {
            backDialog: false,
            description: false
        };
        const goalTypeList = [
            {
                code: 'url',
                text: this.$t('campaign_settings.url')
            },
            {
                code: 'event',
                text: this.$t('campaign_settings.event')
            }
        ];
        const goalConditionList = entryPatternList.filter(item => {
            if (item.value === 'Rex') return hasGoalRexPermission;
            return !item.hide;
        });

        const defaultModel = {
            name: this.$t('goals.untitled_goal'),
            description: null,
            content: {
                type: 'url',
                subType: 'page',
                condition: '[()]',
                value: [],
                where: [],
                visiblePopver: false,
                htmlValue: '',
                visibleCount: 0
            }
        };
        return {
            loading: false,
            isCreated,
            goalConditionList,
            goalTypeList,
            pageTypeList,
            originalPatternList,
            dialogVisible,
            from: this.$route.params.from,
            defaultModel,
            model: cloneUtils.deep(defaultModel),
            eventNameList: [],
            pageDictData: [],
            combinedPageDictData: [],
            currentEventPropertys: null,
            eventPropertyOptions: {},
            eventPropertyValueOptions: {},
            isGoalChange: false,
            updateGoalDialog: false,
            withBandit: false
        };
    },
    mounted() {
        this.isCreated = this.$route.name === 'GoalsCreate';
        if (!this.isCreated) this.fetchGoalSetting();
        this.fetchDictData({ funName: 'visitPage', propertyKey: 'page' }).then(res => {
            const list = res.map(item => {
                return { code: item.key, name: item.key };
            });
            this.pageDictData = list;
        });
        this.fetchDictData({ funName: 'visitPage', propertyKey: 'combinedPage' }).then(res => {
            const list = res.map(item => {
                return { code: item.key, name: item.key };
            });
            this.combinedPageDictData = list;
        });
    },
    computed: {
        ...mapGetters('main', ['profileInfo', 'asideIsFolded', 'userInfo']),
        visibleRexTips() {
            const { type, condition } = this.model?.content || {};
            return type === 'url' && condition === 'Rex';
        },
        validGoalSetting() {
            const { name, content } = this.model;
            const { type, condition, value, where } = content;
            return (
                !!(name && Array.isArray(value) && value.length > 0 && value.join('') !== '') &&
                !where?.find(w => w.error?.type || w.error?.value || !w.key || !w.value || w.value?.join('') === '')
            );
        },
        backText() {
            const backToCampaignRouter = ['CampaignEdit', 'CampaignCreate', 'EngagementCreate', 'EngagementEdit'];
            return backToCampaignRouter.includes(this.from)
                ? this.$t('campaign_settings.back_to_campaign')
                : this.$t('goals.back_to_report');
        },
        descriptionArrowStyle() {
            return {
                right: this.$i18n.locale === 'EN' ? '84px' : '108px'
            };
        }
    },
    watch: {
        'model.content.value': {
            handler(val) {
                (!val || val[0] === '') && this.$set(this.model.content, 'where', []);
            },
            deep: true
        }
    },
    watch: {
        'model.content': {
            handler(val) {
                this.isGoalChange =
                    !this.isCreated &&
                    !isEqual(
                        {
                            condition: val?.condition,
                            subType: val?.subType,
                            type: val?.type,
                            value: val?.value,
                            where: val?.where
                        },
                        this.defaultModel?.content
                    );
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations('main', ['UPDATE_COUNTS', 'UPDATE_PACKAGE_USAGE']),
        fetchGoalSetting() {
            this.loading = true;
            const goalId = this.$route.params.goalId;
            goalApis
                .getGoal(null, {
                    where: {
                        id: goalId
                    }
                })
                .then(res => {
                    const { name, description, content, withBandit } = res;
                    this.withBandit = withBandit;
                    if (
                        content &&
                        ((this.originalPatternList.includes(content.condition) && content.subType) || !content.subType)
                    ) {
                        content.subType = 'page';
                    }

                    if (content && !content.where) {
                        content.where = [];
                    }

                    this.model = {
                        name,
                        description,
                        content
                    };
                    this.defaultModel = cloneUtils.deep(this.model);
                    // 如果是事件类型，则需要请求事件列表
                    if (content && content.type === 'event') {
                        this.fetchEventList();
                        this.fetchEventPropertyList(content.value[0]);
                    }
                    if (content.subType === 'page' || content.subType === 'combinedPage') {
                        const htmlValue = domUtils.getConditionUrls(this.model.content);
                        this.$set(this.model.content, 'htmlValue', htmlValue);
                        this.$set(this.model.content, 'visiblePopver', false);
                        this.handleWord();
                    }
                    this.loading = false;
                });
        },
        handleTestSaveClick() {
            if (this.isGoalChange && !this.isCreated && this.withBandit) {
                this.updateGoalDialog = true;
            } else {
                this.handleSaveClick();
            }
        },
        handleSaveClick() {
            this.formatData();
            this.isCreated ? this.createGoal() : this.updateGoal();
        },
        createGoal() {
            const params = Object.assign({}, this.model, {
                sid: this.profileInfo.sid,
                createBy: this.userInfo.id
            });
            // 给保存的值去除前后空格
            params.content &&
                params.content.value &&
                Array.isArray(params.content.value) &&
                (params.content.value = params.content.value.map(item => {
                    return params.content.type === 'url' && typeof item === 'string' ? item.trim() : item;
                }));
            delete params.content?.htmlValue;
            delete params.content?.visibleCount;
            delete params.content?.visiblePopver;
            const { from, engageId } = this.$route.params;
            var e = this.from || this.$route.params?.back || 'GoalList';
            const ep = {
                GoalsEmpty: 'goal_onbo',
                GoalList: 'goal_list',
                CampaignCreate: 'cp_create_fromv2',
                EngagementCreate: 'cp_create_fromv1'
            };
            analyticsService.usePTX('goal_add_success', { position: ep[e], goal_type: params.content.type }).track();
            goalApis.createGoal(null, { data: params }).then(
                res => {
                    typeof res?.count === 'number' && this.UPDATE_PACKAGE_USAGE({ type: 'goals', count: res.count });
                    this.UPDATE_COUNTS({
                        key: 'goalCount'
                    });
                    this.$router.push({
                        name: from || 'GoalsReport',
                        params: { goalID: res.id, engageId: engageId }
                    });
                },
                err => {
                    const { code } = err;
                    code === 'PTX_PACKAGE_LIMIT_ERROR' &&
                        this.SET_AUTH_DIALOG_VISIBLE({ visible: true, type: 'CREATE_GOAL' });
                }
            );
        },
        updateGoal() {
            const params = Object.assign({}, this.model, {
                updateBy: this.userInfo.id
            });
            // 给保存的值去除前后空格
            params.content &&
                params.content.value &&
                Array.isArray(params.content.value) &&
                (params.content.value = params.content.value.map(item => {
                    return params.content.type === 'url' && typeof item === 'string' ? item.trim() : item;
                }));
            delete params.content?.htmlValue;
            delete params.content?.visibleCount;
            delete params.content?.visiblePopver;
            const { from, engageId } = this.$route.params;
            analyticsService.usePTX('goal_edit').track();
            goalApis
                .updateGoal(null, {
                    data: params,
                    where: { sid: this.profileInfo.sid, id: this.$route.params.goalId },
                    republish: this.isGoalChange
                })
                .then(
                    res => {
                        console.log('update goal success', res);
                        this.$router.push({
                            name: from || 'GoalsReport',
                            params: { goalID: res.id, engageId: engageId }
                        });
                    },
                    err => {
                        console.log(err);
                        const { code } = err;
                        code === 'PTX_PACKAGE_LIMIT_ERROR' &&
                            this.SET_AUTH_DIALOG_VISIBLE({ visible: true, type: 'EDIT_GOAL' });
                    }
                );
        },
        formatData() {
            if (!['[()]', '=='].includes(this.model.content.condition) && this.model.content.value.length > 0) {
                this.$set(this.model.content, 'value', [this.model.content.value[0]]);
            }
            // 如果选的condition 在originalPatternList列表范围内, 则默认重置其subType为page(确保不会有 没有合参的选项的condition 出现subType为合参的情况)
            if (this.originalPatternList.includes(this.model.content.condition)) {
                this.$set(this.model.content, 'subType', 'page');
            }
        },
        handleBackClick() {
            if (isEqual(this.defaultModel, this.model)) {
                this.handleConfirmBack();
            } else {
                this.toggleDialogVisible('backDialog', true);
            }
        },
        toggleDialogVisible(type, visible) {
            this.$set(this.dialogVisible, type, visible);
        },
        handleConfirmBack() {
            const { from, back, engageId } = this.$route.params;
            this.$router.push({
                name: from || back || 'GoalsReport',
                params: { engageId: engageId },
                query: this.$route.query
            });
        },
        handleDescriptionClick() {
            this.$set(this.model, 'description', '');
        },
        async fetchEventList() {
            const [err, dictData] = await commonUtils.awaitWrap(this.fetchDictData({ funName: 'eventName' }));
            this.eventNameList = [];
            if (err || !dictData) {
                console.log('featch Event name list failed');
            } else {
                const eventName = dictData.map(item => {
                    return { code: item.key, name: item.key };
                });
                this.eventNameList = eventName;
            }
        },
        async goalTypeChange(val) {
            if (val === 'event') {
                this.fetchEventList();
            }
            this.$set(this.model.content, 'condition', '[()]');
            this.$set(this.model.content, 'subType', 'page');
            this.$set(this.model.content, 'value', []);
            this.$set(this.model.content, 'where', []);
            this.$set(this.model.content, 'visiblePopver', false);
            this.$set(this.model.content, 'htmlValue', '');
            this.$set(this.model.content, 'visibleCount', 0);
            if (this.model.content.type === 'page' || this.model.content.type === 'combinedPage') {
                this.handleWord();
            }
        },
        /**
         * 切换condition类型时, 判断condition 是否为originalPatternList(没有合参选项的condition列表)里的,是则重置subType为page
         */
        goalConditionChange(val) {
            if (this.originalPatternList.includes(val)) {
                this.$set(this.model.content, 'subType', 'page');
            }
        },
        fetchDictData(params) {
            const { sid, timeZone } = this.profileInfo;
            const { funName, propertyKey } = params;
            return usersApis.getDictData(null, {
                where: {
                    sid,
                    funName,
                    propertyKey,
                    timeZone,
                    limit: 100
                }
            });
        },
        /**
         * Event类型的goal的value自动联想
         */
        querySearchEvent(queryString, cb) {
            var options = this.eventNameList;
            var results = queryString ? options.filter(this.createFilter(queryString)) : options;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return option => {
                return option['code']?.toLowerCase()?.indexOf(queryString?.toLowerCase()) > -1;
            };
        },
        /**
         * 获取事件列表
         */
        fetchEventPropertyList(eventName) {
            this.fetchDictData({
                funName: 'eventPropertyName',
                propertyKey: eventName ?? ''
            }).then(data => {
                const result = this.formatEventPropertyData(data);
                this.currentEventPropertys = result;
                this.updateEventPropertyOptions(eventName, result);
                this.eventPropertyValueOptions = {}; // 更换事件时 其属性值的缓存清空
            });
        },
        handleToggle(status) {
            this.model.content.visiblePopver = status;
        },
        goalValueChange(val) {
            const type = this.model.content.subType;
            if (Array.isArray(val)) {
                this.$set(
                    this.model.content,
                    'value',
                    val.map(item => item.code)
                );
            } else {
                if (!val) {
                    this.$set(this.model.content, 'value', []);
                }
            }
            let v = val?.code || val || '';
            // 如果选择类型为event,则切换后请求event的自定义属性值 (如果有缓存用缓存里的)
            if (this.model.content.type === 'event') {
                this.$set(this.model.content, 'where', []);
                v && !this.eventPropertyOptions[v] && this.fetchEventPropertyList(v);
            }
            if (type === 'page' || type === 'combinedPage') {
                const htmlValue = domUtils.getConditionUrls(this.model.content);
                this.model.content.htmlValue = htmlValue;
                this.handleWord();
            }
        },
        handleWord() {
            domUtils.resetDom(this.$refs.conditionRef);
            this.$nextTick(() => {
                if (this.$refs.conditionRef) {
                    const baseEl =
                        this.model.content.subType === 'page'
                            ? this.$refs.conditionRef.querySelector('div.pageSection')
                            : this.$refs.conditionRef.querySelector('div.combinedPageSection');
                    this.$set(this.model.content, 'visibleCount', domUtils.getConditionCount(baseEl));
                }
            });
        },
        formatEventPropertyData(data) {
            const result = [];
            data.map(item => {
                const { type, key } = item;
                const defaultCondition = type === 'TIME' ? '>=' : '==';
                const valueNeedQuery = type === 'STRING';
                result.push({
                    ...item,
                    name: key, // 添加name字段是为了跟usergroup里的保持一样
                    defaultCondition,
                    valueNeedQuery,
                    allowCreate: type === 'STRING',
                    blurCreate: type === 'STRING',
                    notDisableDate: true
                });
            });
            return result;
        },
        updateEventPropertyOptions(key, value) {
            if (this.eventPropertyOptions.hasOwnProperty(key)) {
                this.eventPropertyOptions[key] = value;
            }
        },
        handleAddEventVariant() {
            const newWhere = cloneUtils.deep(this.model.content?.where || []);
            newWhere.push({
                key: '',
                condition: '',
                type: '',
                value: []
            });
            this.$set(this.model.content, 'where', newWhere);
        },
        handleCacheValueOptions(key, value) {
            this.eventPropertyValueOptions[key] = value;
        },
        handleUpdateWhere(index, value) {
            this.model.content.where[index] = value;
        },
        handleDeleteEventVariant(index) {
            this.model.content.where.splice(index, 1);
        }
    },
    components: {
        BackDialog,
        PageHeader,
        GoalEventVariant,
        ItemMultiSelectWithPopover
    }
};
</script>
<style lang="scss" module>
@import '@/styles/import.scss';

$arrow-width: 10px;
$arrow-height: 13px;

:global {
    .setting-description-enter-active,
    .setting-description-leave-active {
        opacity: 1;
        transform: scaleY(1);
        transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 300ms cubic-bezier(0.23, 1, 0.32, 1);
        transform-origin: center top;
    }
    .setting-description-enter,
    .setting-description-leave-active {
        opacity: 0;
        transform: scaleY(0);
    }
}

.setting {
    padding-bottom: 120px;

    .header {
        padding-bottom: 0;

        &-tools {
            display: flex;
            align-items: center;

            & > svg {
                margin-right: 18px;
                cursor: pointer;
                fill: $pt-black-600;

                &:hover {
                    fill: $pt-black-600;
                }
            }
        }
    }

    .description {
        margin-top: $arrow-height + 18px;
        position: relative;

        @include media-lg {
            .description-icon {
                min-width: 100px;

                img {
                    width: 90px !important;
                    height: 90px !important;
                }
            }

            &-custom .description-icon img {
                width: 50px !important;
                height: 50px !important;
            }
        }

        @include media-md {
            .description-icon {
                display: none;
            }
        }

        &-close {
            cursor: pointer;
            position: absolute;
            top: 16px;
            right: 16px;
            fill: $pt-black-90;

            &:hover {
                fill: $pt-black-300;
            }
        }

        &-custom {
            .description-icon img {
                width: 60px !important;
                height: 60px !important;
            }
        }

        &-arrow {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px $arrow-height 10px;
            border-color: transparent transparent $pt-black-30 transparent;
            position: absolute;
            top: -($arrow-height - 1px);
            z-index: 1;

            &::after {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 $arrow-width - 1px $arrow-height - 1px $arrow-width - 1px;
                border-color: transparent transparent $pt-black-30 transparent;
                position: absolute;
                left: -($arrow-width - 1px);
                top: 1px;
            }
        }

        &-container {
            padding: 54px 54px 36px 48px;
            background-color: $pt-black-30;
            border-radius: 4px;
            display: flex;
            position: relative;
            border: 1px solid $pt-black-30;
        }

        &-icon {
            flex: 0 0 14%;
            min-width: 130px;
            max-width: 125px;

            img {
                width: 70px;
            }
        }

        &-info {
            h3,
            h4,
            h5 {
                color: $pt-black-600;
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 29px;
            }
            h4,
            h5 {
                font-size: 16px;
                line-height: 24px;
            }

            h5 {
                color: $pt-black-600;
                font-size: 12px;
                margin-top: 26px;
            }

            p,
            span,
            a {
                color: $pt-black-600;
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 20px;
                margin-top: 6px;
            }

            span,
            a {
                font-size: 12px;
                margin-top: 4px;
            }

            a {
                display: block;
                cursor: pointer;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &-items {
            border-top: 1px solid $pt-black-50;
            margin-top: 16px;
            padding-top: 16px;
            display: grid;
            grid-auto-flow: column;
            grid-column-gap: 32px;
            grid-row-gap: 16px;

            @include media-md {
                .description-item {
                    grid-column-start: span 2;
                }
            }

            @include media-lg {
                // grid-auto-flow: row;
                // grid-template-columns: repeat(2, 1fr);

                .description-item:first-child {
                    grid-column-start: span 2;
                }
            }
        }
    }

    .container {
        margin-top: 52px;
    }

    .box {
        margin-bottom: 20px;

        & > h4 {
            color: $pt-black-600;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 22px;
        }

        & > button {
            margin: 16px 0 28px;
        }

        &-content {
            margin-top: 8px;
            display: flex;
            .content_item {
                margin-left: 12px;
                .helpIcon {
                    width: 16px;
                    height: 16px;
                    fill: $pt-black-600;
                    margin-left: 4px;
                    vertical-align: text-bottom;
                }
            }
            .content_item_pagetype {
                width: 210px;
                :global {
                    .el-select {
                        width: 100%;
                    }
                }
            }
            .content_item_value {
                width: 400px;
            }
            .iconButton {
                display: inline-block;
                width: 32px;
                min-width: 32px;
                height: 32px;
                padding: 4px;
                border-radius: 3px;
                margin-left: 8px;
                &:hover {
                    background-color: $pt-black-40;
                }
                svg {
                    width: 24px;
                    height: 24px;
                    fill: #5b6c86;
                }
            }
        }
        .eventVariant {
            padding-left: 30px;
        }
        .rexTips {
            max-width: 810px;
            padding: 12px 16px;
            border-radius: 4px;
            border: 1px solid #a6c8ff;
            background-color: $pt-gray-10;
            color: #344563;
            margin-top: 10px;
            font-size: 12px;

            h3 {
                font-size: 13px;
                font-weight: 500;
                margin-bottom: 4px;
            }

            p {
                line-height: 20px;
            }

            a {
                color: $pt-green-60;
                @extend %link;

                &:hover {
                    color: $pt-green-60;
                }
            }
        }

        &-ipt {
            width: 100%;
            max-width: 600px;
        }

        &-textarea {
            height: 62px;
        }
    }

    .filter-add {
        border: none;
        background: transparent;
        @include link($pt-green-60, $pt-green-70);
    }

    .overview {
        margin-top: 68px;
        padding-bottom: $page-content-padding-bottom * 2;

        &-title {
            color: $pt-black-600;
            display: flex;
            flex-direction: column;
            margin-bottom: 60px;

            h4 {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 24px;
            }

            p {
                margin-top: 10px;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0;
                line-height: 19px;
                display: flex;
                align-items: center;

                i {
                    width: 1px;
                    height: 24px;
                    background-color: $pt-black-300;
                    margin: 0 20px;
                }
            }

            span {
                color: $pt-green-60;
                font-size: 28px;
                font-weight: 400;
                letter-spacing: 0;
                line-height: 38px;
                margin-left: 10px;
            }
        }

        &-box {
            box-sizing: border-box;
            height: 284px;
            border: 1px solid $pt-black-40;
            border-radius: 2px;
            background-color: $pt-white;

            &:not(:last-child) {
                margin-bottom: 40px;
            }
        }

        &-list {
            min-width: 600px;

            .row {
                color: $pt-black-600;
                font-size: 14px;
                line-height: 24px;
                display: grid;
                grid-template-columns: repeat(12, 1fr);
                border-top: 1px solid $pt-black-40;
                padding: 30px 0;

                &-header {
                    padding: 0;
                    border-top: none;
                    height: 30px;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 19px;
                }

                &:hover:not(.row-header) {
                    background-color: $pt-black-20;
                }
            }

            .cell {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                padding-right: 6px;

                &.icon {
                    @extend %flex-center;
                    padding: 0;

                    span {
                        height: 32px;
                        width: 32px;
                        border-radius: 50%;
                        color: $pt-white;
                        font-size: 16px;
                        letter-spacing: 0;
                        @extend %flex-center;
                    }
                }

                &.email {
                    font-size: 16px;
                }

                &-span1 {
                    grid-column-start: span 1;
                }
                &-span2 {
                    grid-column-start: span 2;
                }
                &-span3 {
                    grid-column-start: span 3;
                }
            }

            .lenTips {
                font-size: 12px;
                text-align: left;
                padding: 10px 0;
                color: $pt-black-60;
            }
        }
    }
}
</style>
