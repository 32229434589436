<template>
    <el-dialog center width="440px" :visible="true" :title="$t('common.title_confirm')" @close="handleCancelClick">
        <div class="dialog-content">
            <p class="des">{{ $t('goals.back_dialog') }}</p>
        </div>
        <div slot="footer" class="dialog-footer">
            <pt-button size="medium" @click="handleConfirmClick">{{
                $t('common.button_confirm')
            }}</pt-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'BackDialog',

    methods: {
        handleConfirmClick() {
            this.$emit('confirm');
        },

        handleCancelClick() {
            this.$emit('cancel');
        }
    }
};
</script>
