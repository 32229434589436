"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var composition_api_1 = require("@vue/composition-api");
var FilterCondition_vue_1 = require("@/components/users/setting/filter/content/FilterCondition.vue");
var FilterValue_vue_1 = require("@/components/users/setting/filter/content/FilterValue.vue");
var Filter_vue_1 = require("@/components/users/setting/filter/content/Filter.vue");
var users_apis_1 = require("@/apis/users.apis");
var axios_1 = require("axios");
var lodash_es_1 = require("lodash-es");
var usergrp_util_1 = require("@/components/users/usergrp-util");
exports.default = (0, composition_api_1.defineComponent)({
    name: 'GoalEventVariant',
    props: ['eventName', 'valueOptions', 'propertyValueOptions', 'sid', 'timeZone', 'filter', 'filterLen', 'index'],
    setup: function (props, context) {
        var root = context.root, emit = context.emit;
        var state = (0, composition_api_1.reactive)({
            filterItem: props.filter,
            filterCondition: null,
            filterValue: null //过滤值
        });
        var visibleStates = (0, composition_api_1.ref)({
        // filterItem: true,
        // filterCondition: false,
        // filterValue: false
        });
        var options = (0, composition_api_1.reactive)({
            filterItem: props.valueOptions,
            filterCondition: null,
            filterValue: null
        });
        //接口请求状态
        var fetchStates = (0, composition_api_1.reactive)({
            filterValue: {
                loading: false,
                reqId: 'fetchValueOptions'
            }
        });
        var currFilter = (0, composition_api_1.ref)(props.filter || {});
        // computed
        var filterValueType = (0, composition_api_1.computed)(function () {
            var filterItem = state.filterItem, filterCondition = state.filterCondition;
            return (filterItem && filterItem.cType) || (filterCondition && filterCondition.cType); //有指定组件类型则优先使用
        });
        // watch
        (0, composition_api_1.watch)(function () { return props.filter; }, function (val) {
            val && (currFilter.value = val);
            init();
        });
        // onMounted
        (0, composition_api_1.onMounted)(function () {
            init();
        });
        // onUnmounted
        // function
        function init() {
            var _a;
            /**
             * where里的结构
             * {
             *    key: 'xxx',
             *    condition:'xxxx',
             *    type:'xx',  // 数据类型 STRING NUMBER TIME
             *    value:['dd','aa']
             *    }
             */
            // debugger;
            var _b = currFilter.value, key = _b.key, type = _b.type, condition = _b.condition, value = _b.value;
            setState('filterItem', key, 'key');
            setFilterConditionOptions();
            setState('filterCondition', condition || ((_a = state.filterItem) === null || _a === void 0 ? void 0 : _a.defaultCondition));
            setFilterValueOptions();
            updateStates('filterValue', value);
            updateVisibleStates();
        }
        function handleChange(type, value) {
            // console.log('handleChange is triggered', type, value);
            var needUpdate = true;
            switch (type) {
                case 'filterItem':
                    var defaultCondition = value.defaultCondition;
                    updateStates('filterItem', value);
                    setFilterConditionOptions();
                    setState('filterCondition', defaultCondition);
                    setFilterValueOptions();
                    updateStates('filterValue');
                    updateVisibleStates();
                    // 如果没有Condition，则说明切换item即为新增完成
                    needUpdate = (options.filterCondition || []).length === 0;
                    break;
                case 'filterCondition':
                case 'filterValue':
                    updateStates(type, value);
                    updateVisibleStates();
                    break;
                default:
                    updateStates(type, value);
                    break;
            }
            _updateFilter(type, needUpdate);
        }
        /**
         * 获取过滤器条件选项列表
         */
        function setFilterConditionOptions() {
            var type = (state.filterItem || {}).type;
            var optionsVar = type ? (0, usergrp_util_1.getNextStepForCondition)(type === null || type === void 0 ? void 0 : type.toLocaleLowerCase(), true) : [];
            updateOptions('filterCondition', optionsVar);
        }
        function fetchDictData(params) {
            // const { sid, timeZone } = this.profileInfo;
            var funName = params.funName, propertyKey = params.propertyKey, subKey = params.subKey;
            return users_apis_1.default.getDictData(null, {
                where: {
                    sid: props.sid,
                    funName: funName,
                    propertyKey: propertyKey,
                    subKey: subKey,
                    timeZone: props.timeZone,
                    limit: 100
                }
            });
        }
        /**
         * 设置过滤器值选项列表
         */
        function setFilterValueOptions() {
            return __awaiter(this, void 0, void 0, function () {
                var _a, key, valueNeedQuery;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = state.filterItem || {}, key = _a.key, valueNeedQuery = _a.valueNeedQuery;
                            // if (props.propertyValueOptions && props.propertyValueOptions?.[key]) {
                            //     updateOptions('filterValue', props.propertyValueOptions?.[key]);
                            //     return;
                            // }
                            fetchStates.filterValue.loading && _cancelFeatch(fetchStates.filterValue.reqId);
                            fetchStates.filterValue.loading = true;
                            if (!valueNeedQuery) return [3 /*break*/, 2];
                            return [4 /*yield*/, fetchDictData({
                                    funName: 'eventPropertyValue',
                                    propertyKey: props.eventName,
                                    subKey: key
                                }).then(function (data) {
                                    var results = (data === null || data === void 0 ? void 0 : data.map(function (item) {
                                        return {
                                            code: item.key,
                                            name: item.key
                                        };
                                    })) || [];
                                    fetchStates.filterValue.loading = false;
                                    updateOptions('filterValue', results);
                                    key && emit('updateItems', key, results);
                                })];
                        case 1:
                            _b.sent();
                            _b.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
        function updateOptions(key, value) {
            if (value === void 0) { value = []; }
            if (Object.prototype.hasOwnProperty.call(options, key)) {
                options[key] = value;
            }
        }
        function updateStates(key, value) {
            if (value === void 0) { value = null; }
            if (Object.prototype.hasOwnProperty.call(state, key)) {
                state[key] = value;
            }
        }
        /**
         * 更新组件显示状态
         */
        function updateVisibleStates() {
            var filterItem = state.filterItem, filterValue = state.filterValue, filterCondition = state.filterCondition;
            var _a = filterItem || {}, key = _a.key, type = _a.type;
            var hasFilterValue = Boolean(filterCondition) ? Boolean(filterValue) : true;
            visibleStates.value = {};
            //只保留会展示的组件
            Object.keys(state).forEach(function (stateName) {
                var visible = true;
                var hasComponent = true;
                switch (stateName) {
                    case 'filterCondition':
                        hasComponent = Boolean(key);
                        visible = Boolean(filterItem);
                        break;
                    case 'filterValue':
                        var hasValue = (filterCondition || {}).hasValue;
                        hasComponent = hasValue;
                        visible = Boolean(filterItem);
                        break;
                }
                hasComponent && (visibleStates.value[stateName] = visible);
            });
        }
        /**
         * 更新当前过滤器值
         */
        function _updateFilter(stateName, needUpdate) {
            var filterItem = state.filterItem, filterCondition = state.filterCondition, filterValue = state.filterValue;
            var mapper = {
                filterItem: function () {
                    if (!filterItem)
                        return;
                    var key = filterItem.key, itemType = filterItem.type, defaultCondition = filterItem.defaultCondition;
                    updateFilter('key', key);
                    updateFilter('type', itemType);
                    updateFilter('value', null);
                    updateFilter('condition', defaultCondition);
                },
                filterCondition: function () {
                    var _a = filterCondition || {}, code = _a.code, hasValue = _a.hasValue;
                    updateFilter('condition', code);
                    hasValue || updateFilter('value', []);
                },
                filterValue: function () {
                    var curVal = (filterValue !== undefined &&
                        (Array.isArray(filterValue) ? filterValue : [filterValue])) ||
                        null;
                    updateFilter('value', curVal);
                }
            };
            mapper[stateName] && mapper[stateName]();
            needUpdate && notice();
        }
        function notice() {
            var _a = currFilter.value, type = _a.type, condition = _a.condition, value = _a.value;
            var _b = visibleStates.value, filterCondition = _b.filterCondition, filterValue = _b.filterValue;
            var hasFilterItem = Boolean(type);
            var visibleFilterCondition = Boolean(filterCondition);
            var hasValue = filterValue === undefined ||
                (Array.isArray(value) &&
                    value.length > 0 &&
                    value.some(function (item) { return item === 0 || Boolean(item); }));
            currFilter.value['error'] = {
                type: !hasFilterItem,
                value: !hasValue
            };
            if (hasFilterItem && hasValue) {
                emit('change', (0, lodash_es_1.cloneDeep)(currFilter.value));
            }
        }
        function updateFilter(key, value) {
            currFilter.value[key] = value;
        }
        /**
         * 设置状态值
         */
        function setState(type, code, val) {
            var optionsVar = options[type] || [];
            var itemInfo = val
                ? code && (0, usergrp_util_1.searchTree)(optionsVar, code, val)
                : code && (0, usergrp_util_1.searchTree)(optionsVar, code);
            updateStates(type, itemInfo);
        }
        function handleDelete() {
            emit('delete');
        }
        function createFilter(queryString) {
            return function (option) {
                return option['key'].toLowerCase().indexOf(queryString.toLowerCase()) > -1;
            };
        }
        function querySearchEvent(queryString, cb) {
            var options = props.valueOptions;
            var results = queryString ? options.filter(createFilter(queryString)) : options;
            // 调用 callback 返回建议列表的数据
            cb(results);
        }
        function _cancelFeatch(reqId) {
            axios_1.default.cancel(reqId);
        }
        return {
            state: state,
            visibleStates: visibleStates,
            options: options,
            fetchStates: fetchStates,
            currFilter: currFilter,
            filterValueType: filterValueType,
            handleChange: handleChange,
            handleDelete: handleDelete,
            querySearchEvent: querySearchEvent
        };
    },
    components: {
        FilterCondition: FilterCondition_vue_1.default,
        FilterValue: FilterValue_vue_1.default,
        FilterContent: Filter_vue_1.default
    }
});
